<template>
	<div>
		<Modal
			class="modal-error"
			:model-value="getVisibility"
			@update:model-value="closeError">
			<ModalContent
				:modal-title="getIsErrorMessage ? 'Ошибка' : 'Предупреждение'"
				:modal-title-icon="'warning'"
				:modal-title-icon-fill="false"
				:modal-description="getMessage">
				<template #modalControls>
					<UiButton
						:name="'Подтвердить'"
						:size="'md'"
						@click="closeError" />
				</template>
			</ModalContent>
		</Modal>
	</div>
</template>

<script setup>
	import { storeToRefs } from "pinia";

	const {
		modalErrorStore,
		modalErrorStore: { close: closeError },
	} = useStores();
	const { getVisibility, getMessage, getIsErrorMessage } = storeToRefs(modalErrorStore);
</script>

<style scoped lang="scss">
	@import "modal-error";
</style>
